// extracted by mini-css-extract-plugin
export var ArtistDescription = "DanielArsham-module--ArtistDescription--cwVpN";
export var ArtistInfos = "DanielArsham-module--ArtistInfos--yiHvp";
export var ButtonWrapper = "DanielArsham-module--ButtonWrapper --47xRe";
export var CardWrapper = "DanielArsham-module--CardWrapper--PSThp";
export var CarrouselWrapper2 = "DanielArsham-module--CarrouselWrapper2--RJNhZ";
export var Citations = "DanielArsham-module--Citations--kFwG6";
export var DescriptionWrapper = "DanielArsham-module--DescriptionWrapper--sHZ6m";
export var ImageWrapper = "DanielArsham-module--ImageWrapper--sLw40";
export var LinkWrapper = "DanielArsham-module--LinkWrapper--VY1l7";
export var MobileProtrait = "DanielArsham-module--MobileProtrait--jf0Nr";
export var More = "DanielArsham-module--More--HX2u9";
export var MoreButton = "DanielArsham-module--MoreButton--2u9v0";
export var NameWrapper = "DanielArsham-module--NameWrapper--UjggA";
export var PdpWrapper = "DanielArsham-module--PdpWrapper--kNymL";
export var PhotosWrapper = "DanielArsham-module--PhotosWrapper--nB7np";
export var ProfilWrapper = "DanielArsham-module--ProfilWrapper--7B85X";
export var TitleWrapper = "DanielArsham-module--TitleWrapper---GV0F";
export var Wrapper = "DanielArsham-module--Wrapper--5cFj7";